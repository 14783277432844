<section class="sec-container">
  <a
    class="banner"
    href="https://pg.empreenderdinheiro.com.br/wealth-planner/"
    target="_blank"
  >
    <img src="/assets/images/homeTF/Wealth Planner.png" alt="" />
  </a>
  <div class="cards-local">
    <app-card-nolink
      class="education-card"
      [title]="'MasterClasses'"
      [subtitle]="
        'Aulas gratuitas que valem um curso para acelerar seus resultados'
      "
      [imgPath]="'/assets/images/homeTF/ChalkboardTeacher.svg'"
      [block]="true"
      [tag]="'Em breve'"
    >
    </app-card-nolink>

    <app-card-link
      class="education-card"
      [title]="'Palestrante TechFinance®'"
      [subtitle]="'Torne-se um palestrante TechFinance®'"
      [imgPath]="'/assets/images/homeTF/MicrophoneStage.svg'"
      [link]="
        'https://empreenderdinheiro.com.br/palestrante-techfinance/'
      "
    ></app-card-link>
  </div>
</section>
