import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-modal',
  templateUrl: './software-modal.component.html',
  styleUrls: ['./software-modal.component.scss'],
})
export class SoftwareModalComponent implements OnInit {
  constructor() {}

  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;

  ngOnInit(): void {}
}
