<section class="sec-container">
  <div class="card-local">
    <div class="row">
      <app-card-link
        [title]="'Guia'"
        class="col"
        [subtitle]="'Consultoria Express'"
        [imgPath]="'/assets/images/homeTF/logo-guia.svg'"
        [link]="'https://guia.tf.empreenderdinheiro.com.br/'"
      ></app-card-link>
      <app-card-nolink
        [title]="'Apolo'"
        class="col"
        [subtitle]="'Planejamento Patrimonial'"
        [imgPath]="'/assets/images/homeTF/logo-apolo.svg'"
        (click)="openApplication('apolo.tf')"
      ></app-card-nolink>

      <div class="row">

      <app-card-nolink
        [title]="'Oráculo'"
        class="col"
        [subtitle]="'Asset Allocation'"
        [imgPath]="'/assets/images/homeTF/logo-oraculo.svg'"
        (click)="openApplication('oraculo.tf')"
      ></app-card-nolink>
      <app-card-nolink
        [title]="'Preciso'"
        class="col"
        [subtitle]="'Planejamento Orçamentário'"
        [imgPath]="'/assets/images/homeTF/logo-preciso.svg'"
        (click)="openModal('preciso')"
      ></app-card-nolink>
    </div>
  </div>

  </div>
</section>
  

