<div class="container_full">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8">
        <h3>
          Fale Conosco:
        </h3>
        <p style="color: #094035;font-size: 16px;">
          Saiba que <b>estamos sempre torcendo por você</b> e por seu sucesso financeiro. <br>Escolha uma das
          opções e
          <b>entre
            em
            contato
            conosco</b>:
        </p>
      </div>
    </div>
    <section>
      <div class="row">
        <div class="col-md-4">
          <a class="item" target="_blank" href="https://empreenderdinheiro.com.br/tf-fale-experiencia/">
            <div class="image">
              <img src="/assets/images/suporte-1.png" alt="">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="item" target="_blank" href="https://empreenderdinheiro.com.br/tf-fale-suporte/">
            <div class="image">
              <img src="/assets/images/suporte-2.png" alt="">
            </div>
          </a>
        </div>
        <div class="col-md-4">
          <a class="item" target="_blank" href="https://empreenderdinheiro.com.br/tf-fale-comercial/">
            <div class="image">
              <img src="/assets/images/suporte-3.png" alt="">
            </div>
          </a>
        </div>
      </div>
    </section>
    <hr style="margin-top: 96px;">
    <div style="text-align: left;">
      <button class="buttons" style="color: #fff;" mat-flat-button color="primary" routerLink="/">
        <mat-icon matListIcon>arrow_back</mat-icon>

        Voltar ao inicio
      </button>
    </div>
  </div>
</div>
