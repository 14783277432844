import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, AfterViewInit {
  currentPage: string = 'softwares';
  currentUser: any;
  showDisplayNotification = false;

  @ViewChild('footer') footer!: ElementRef; // Refere-se ao footer no template

  constructor(private oidcSecurityService: OidcSecurityService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.setDataProfile();
    this.initializeNotificationDisplay();
  }

  ngAfterViewInit(): void {
    this.toggleFooterPosition(); // Chama a função após a renderização
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.toggleFooterPosition(); // Atualiza a posição do footer ao redimensionar
  }

  private setDataProfile(): void {
    this.oidcSecurityService.userData$.subscribe((profileData) => {
      this.currentUser = profileData;
    });
  }

  update(event: string): void {
    this.currentPage = event;

    // Usando setTimeout para garantir que a página seja atualizada antes de calcular
    setTimeout(() => {
      this.toggleFooterPosition();
    }, 0); // Pequeno atraso para permitir a atualização do DOM
  }

  openDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'true');
    this.showDisplayNotification = true;
  }

  closeDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'false');
    this.showDisplayNotification = false;
  }

  private initializeNotificationDisplay(): void {
    const showDisplayNotification = localStorage.getItem('show_display_notification');

    if (showDisplayNotification == null || showDisplayNotification == 'true') {
      this.openDisplayNotification();
    } else {
      this.closeDisplayNotification();
    }
  }

  // Verifica se a barra de rolagem está presente e ajusta a posição do footer
  private toggleFooterPosition(): void {
    const contentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;

    if (contentHeight > windowHeight) {
      this.renderer.removeClass(this.footer.nativeElement, 'fixed-bottom');
    } else {
      this.renderer.addClass(this.footer.nativeElement, 'fixed-bottom');
    }
  }
}
