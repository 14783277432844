<div style="width: 480px; max-width: 100%;">
  <div *ngIf="simpleRouteEnum=='home'">
    <h4 style="font-weight: 600; margin-bottom: 12px;">O que deseja fazer?</h4>
    <div class="mat-list-content">

      <mat-nav-list>
        <a (click)="openLink('login', '/user/members/create?redirect=' + this.application)" mat-list-item
          class="mat-list-item-primary">
          <mat-icon mat-list-icon>person_add_outlined</mat-icon>
          <span class="application-item-title" mat-line>Cadastrar cliente</span>
          <span class="application-item-description" mat-line>
            Clique aqui para <b>cadastrar um novo cliente</b>. Os dados deste cliente ficarão armazenados, possibilitando que você os acesse quando necessário.
          </span>
        </a>
        <a (click)="simpleRouteEnum = 'select-client'" mat-list-item class="mat-list-item-secondary">
          <mat-icon mat-list-icon>fact_check_outlined</mat-icon>
          <span class="application-item-title" mat-line>Selecionar um cliente</span>
          <span class="application-item-description" mat-line>Clique aqui para <b>ver os clientes já
              cadastrados</b>, fazer novos estudos ou acessar antigos.</span>
        </a>
        <a (click)="simpleRouteEnum = 'my-studies'" mat-list-item>
          <mat-icon mat-list-icon>open_in_new_outlined</mat-icon>
          <span class="application-item-title" mat-line>Estudo Pessoal</span>
          <span class="application-item-description" mat-line>Clique para <b>ver os seus Estudos Pessoais</b> já
            cadastrados ou fazer novos estudos.</span>
        </a>
      </mat-nav-list>
    </div>
  </div>
  <app-select-client-study [application]="application" [clientId]="myId"
    *ngIf="simpleRouteEnum=='my-studies' && myId !== undefined" [isPersonStudies]="true"></app-select-client-study>
  <app-select-client [application]="application" *ngIf="simpleRouteEnum=='select-client'"></app-select-client>
</div>
