import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ModalStartComponent } from 'src/app/modal-start/modal-start.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(
    public authService: OidcSecurityService,
    public router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  openModal() {
    let dialogRef = this.dialog.open(ModalStartComponent, {
      height: '470px',
      width: '620px',
    });
  }
  loginRoute(route) {
    location.href =
      environment.clientRedirect
        .replace('techfinance', 'login')
        .replace('qa', 'qa.api')
        .replace('login.tf', 'login') + route;
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }
  logout() {
    this.authService.logoff();
  }
}
