import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  currentPage: string = 'softwares';
  currentUser: any;
  showDisplayNotification = false;

  constructor(private oidcSecurityService: OidcSecurityService) {}

  ngOnInit(): void {
    this.setDataProfile();
    this.initializeNotificationDisplay();
    console.log('ok');
  }

  private setDataProfile(): void {
    this.oidcSecurityService.userData$.subscribe((profileData) => {
      this.currentUser = profileData;
    });
  }

  update(event: string): void {
    this.currentPage = event;
  }

  openDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'true');
    this.showDisplayNotification = true;
  }

  closeDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'false');
    this.showDisplayNotification = false;
  }

  private initializeNotificationDisplay(): void {
    const showDisplayNotification = localStorage.getItem(
      'show_display_notification'
    );

    if (showDisplayNotification == null || showDisplayNotification == 'true') {
      this.openDisplayNotification();
    } else {
      this.closeDisplayNotification();
    }
  }
}
