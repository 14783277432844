import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-carousel',
  templateUrl: './content-carousel.component.html',
  styleUrls: ['./content-carousel.component.scss'],
})
export class ContentCarouselComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  @Input() imgUrl;
  @Input() btnText;
  @Input() iconUrl;
  @Input() link;

  active: boolean = false;

  getActive() {
    return this.active;
  }

  constructor() {}

  ngOnInit(): void {}
}
