<div class="login-main">
  <div class="vertical-align">
    <img src="/assets/images/TF logo.png" style="max-width: 240px; margin: 20px auto; display: block"
      alt="Empreender Dinheiro" />
    <div class="row center">
      <div class="col-md-12 col-sm-12 vertical-align">
        <div class="login-container">
          <div class="loading">
            <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
          </div>

          <h5><b> Bem-vindo</b>.</h5>
          <p>Por favor, efetue seu login.</p>

          <form (submit)="submit()" [formGroup]="loginForm">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input autocomplete="email" autofocus id="emailInput" formControlName="email" matInput
                placeholder="exemplo: andre@empreenderdinheiro.com.br" />
              <div matSuffix>
                <i class="icon-user"></i>
              </div>
              <mat-hint class="hint-error">
                <div *ngIf="
                    loginForm.controls['email'].errors &&
                    !loginForm.controls['email'].pristine
                  " [hidden]="!loginForm.controls['email'].errors.required">
                  Por favor preencha o campo de e-mail.
                </div>
                <div *ngIf="
                    loginForm.controls['email'].errors &&
                    !loginForm.controls['email'].pristine &&
                    !loginForm.controls['email'].errors.required
                  " [hidden]="!loginForm.controls['email'].errors.patternInvalid">
                  Este e-mail é invalido.
                </div>
              </mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Senha</mat-label>
              <input autocomplete="password" id="senhaInput" formControlName="password" type="password" matInput
                placeholder="exemplo: senha123" />
              <div matSuffix>
                <i class="icon-key"></i>
              </div>
              <mat-hint class="hint-error">
                <div *ngIf="
                    loginForm.controls['password'].errors &&
                    !loginForm.controls['password'].pristine
                  " [hidden]="!loginForm.controls['password'].errors.required">
                  Por favor preencha com sua senha.
                </div>
              </mat-hint>
            </mat-form-field>
            <div *ngIf="error" style="
                padding: 10px 12px;
                margin-bottom: 10px;
                border: 1px #c300004a solid;
                border-radius: 5px;
                background: rgba(255, 0, 0, 0.05);
                color: #c30000;
              ">
              {{ error }}
            </div>
            <div style="margin-top: 10px">
              <button [disabled]="!loginForm.valid || loading" type="submit" [disabled]="loading" mat-raised-button
                style="float: right" color="primary">
                Entrar
              </button>
              <button type="button" routerLink="/cadastro" [disabled]="loading" mat-stroked-button color="primary">
                Criar Conta
              </button>
            </div>
            <div style="
    margin-top: 30px;
    color: #787474;
    font-size: 16px;
">
              Esqueceu sua senha? <a style="color:#383838;" routerLink="/recuperar-senha">Clique aqui</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>