<header class="bg-default-white d-flex shadow border-bottom">
  <app-header class="content-container bg-default-white"></app-header>
</header>
<div class="alert alert-primary" *ngIf="showDisplayNotification" role="alert">
  <div class="align-items-center">
    <div class="d-flex justify-content-between">
      <div></div>
      <div class="">
        <div
          class="btn btn-sm border border-secondary p-2 font-weight-bold mr-2"
          style="border-color: #5e9ef4 !important"
        >
          <div class="mx-3">
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2832 5.90372L9.95091 6.23605H8.86234L9.33452 5.76387L7.88483 3.06162V14L4.7168 8.0957L5.04913 7.76337H6.1377L5.66552 8.23555L7.11521 10.9378V0L10.2832 5.90372Z"
                fill="#001E2E"
              />
              <path
                d="M6.6779 6.61492H3.5622L6.26386 5.16523L6.6779 5.57897V4.49069L6.40401 4.2168L0.5 7.38454H6.6779V6.61492Z"
                fill="#001E2E"
              />
              <path
                d="M14.5 6.61523L8.59632 9.78269L8.32214 9.50909V8.42051L8.73617 8.83454L11.4375 7.38486H8.32214V6.61523H14.5Z"
                fill="#001E2E"
              />
              <path
                d="M3.80739 1.91161L3.26318 2.45581L4.34768 3.54031L4.89189 2.99611L3.80739 1.91161Z"
                fill="#001E2E"
              />
              <path
                d="M11.1922 1.9111L10.1077 2.99561L10.6519 3.53981L11.7364 2.45531L11.1922 1.9111Z"
                fill="#001E2E"
              />
              <path
                d="M4.34793 10.0915L3.26343 11.176L3.80763 11.7202L4.89213 10.6357L4.34793 10.0915Z"
                fill="#001E2E"
              />
              <path
                d="M10.6524 10.0919L10.1082 10.6361L11.1927 11.7206L11.7369 11.1764L10.6524 10.0919Z"
                fill="#001E2E"
              />
            </svg>
            <span>Guia</span>
          </div>
        </div>
        <span class="p-2">
          Nova Função Editar Proposta de Trabalho já está disponível!
        </span>
        <a
          class="btn font-weight-bold"
          href="https://empreenderdinheiro.com.br/guia-proposta-de-trabalho/"
          target="_blank"
          ><span>Conferir Atualização </span>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3538 8.35378L9.85375 12.8538C9.75993 12.9476 9.63268 13.0003 9.5 13.0003C9.36732 13.0003 9.24007 12.9476 9.14625 12.8538C9.05243 12.76 8.99972 12.6327 8.99972 12.5C8.99972 12.3674 9.05243 12.2401 9.14625 12.1463L12.7931 8.50003H3C2.86739 8.50003 2.74021 8.44736 2.64645 8.35359C2.55268 8.25982 2.5 8.13264 2.5 8.00003C2.5 7.86743 2.55268 7.74025 2.64645 7.64648C2.74021 7.55271 2.86739 7.50003 3 7.50003H12.7931L9.14625 3.85378C9.05243 3.75996 8.99972 3.63272 8.99972 3.50003C8.99972 3.36735 9.05243 3.2401 9.14625 3.14628C9.24007 3.05246 9.36732 2.99976 9.5 2.99976C9.63268 2.99976 9.75993 3.05246 9.85375 3.14628L14.3538 7.64628C14.4002 7.69272 14.4371 7.74786 14.4623 7.80856C14.4874 7.86926 14.5004 7.93433 14.5004 8.00003C14.5004 8.06574 14.4874 8.13081 14.4623 8.1915C14.4371 8.2522 14.4002 8.30735 14.3538 8.35378Z"
              fill="#001E2E"
            />
          </svg>
        </a>
      </div>
      <div>
        <button
          type="button"
          (click)="closeDisplayNotification()"
          class="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
<section class="flex-col bg-figma-white">
  <div class="welcome h2 content-container bg-figma-white p-5">
    Bem-vindo à TechFinance®, {{ currentUser.user.firstName }}!
  </div>
  <app-menu
    class="content-container bg-figma-white"
    (page)="update($event)"
  ></app-menu>
  <app-softwares
    *ngIf="currentPage == 'softwares'"
    class="content-container"
  ></app-softwares>
  <app-education
    *ngIf="currentPage == 'education'"
    class="content-container"
  ></app-education>
  <app-ecosystem
    *ngIf="currentPage == 'ecosystem'"
    class="content-container"
  ></app-ecosystem>
  <app-material
    *ngIf="currentPage == 'material'"
    class="content-container"
  ></app-material>

  <!-- <app-carousel class="content-container"> -->
  <!-- <app-content-carousel
      [title]="'Consultoria Financeira Empresarial'"
      [subtitle]="
        'Ajude pequenas e médias empresas e seja muito bem-remunerado(a) por isso. Conheça nossa solução Corporate e turbine seu volume de receitas.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-tfb.png'"
      [btnText]="'agendar demonstração'"
      [iconUrl]="'/assets/images/homeTF/icon-right.svg'"
      [link]="
        'https://pg.empreenderdinheiro.com.br/pc/demonstracao-techfinance-business/'
      "
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'18 DE MARÇO, ÀS 19H:30 AO VIVO NO YOUTUBE | EXCLUSIVO'"
      [subtitle]="
        'O novo (e não convencional) caminho para faturar 30 mil mensais recorrentes com Wealth Planning. Exclusivo para associados TechFinance®.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-o-novo-metodo-tf.png'"
      [btnText]="'Quero meu convite para conhecer o novo método!'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="
        'https://pg.empreenderdinheiro.com.br/lc/masterclass-o-novo-metodo/?utm_source=area-de-membros-tf&utm_medium=banner-tf&utm_campaign=ec_geracao-leads_MASTERCLASS-NOVO-METODO-EC'
      "
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'Torne sua empresa um escritório credenciado à ED'"
      [subtitle]="
        'Se seu objetivo é liderar um time de Wealth Planners, ter seu próprio escritório e escalabilidade, conheça o modelo de escritórios credenciados.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-escritorio.png'"
      [btnText]="'Quero Conhecer o modelo'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="'https://empreenderdinheiro.com.br/escritorio-ed-wpp'"
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'Está sem tempo para aprender?'"
      [subtitle]="
        'Acesse gratuitamente o Clube do Livro e descubra o caminho para dominar o conhecimento das maiores mentes do mercado.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-club-do-livro.png'"
      [btnText]="'acessar clube do livro'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="'https://open.spotify.com/user/fk8flssez347genij7r2vz8ms'"
    ></app-content-carousel> -->
  <!-- </app-carousel> -->
  <hr class="my-4" />
</section>
<div class="d-flex justify-content-center bg-figma-white">
  <a
    [href]="'https://pg.empreenderdinheiro.com.br/lv/money-heroes-2024/'"
    target="_blank"
    class="text-decoration-none link-blur"
  >
    <div>
      <img
        [src]="'/assets/images/homeTF/BANNER- MONEY HEROES (TF) - 08 2024.png'"
        alt="Banner TechFinance Business"
        class="img-fluid rounded"
        style="width: 1360px"
      />
    </div>
  </a>
</div>
<div class="p-4 bg-figma-white"></div>
<footer class="d-flex bg-default-white border-top">
  <app-footer class="content-container"></app-footer>
</footer>
