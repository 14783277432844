import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersApplicationsService } from '../users-applications.service';
import { map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit {

  search = new FormControl();
  @ViewChild('searchElement') searchElement: ElementRef;
  usersList: any[] = [];
  @Input()
  application: string;
  clientId: string;
  loading = false;

  constructor(
    public usersApplicationsService: UsersApplicationsService,
  ) { }

  ngOnInit(): void {
    
    this.search.valueChanges
      .pipe(
        startWith(''),
        tap(() => this.loading = true),
        switchMap((search) => this.usersApplicationsService.index({search})),
        map((response: any) => response.results),
        tap(() => this.loading = false),
      )
      .subscribe((usersList: any[]) => {
        this.usersList = usersList;
      });

    setTimeout(()=>{
      this.searchElement.nativeElement.focus();
    },0);  
  }

  openLink(id: string) {
    this.clientId = id;
  }
}
