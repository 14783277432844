import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-nolink',
  templateUrl: './card-nolink.component.html',
  styleUrls: ['./card-nolink.component.scss'],
})
export class CardNolinkComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() imgPath: string;
  @Input() block: boolean = false;
  @Input() tag: string;
  @Input() description: string;
  @Input() isModal: boolean;

  constructor() {}

  ngOnInit(): void {}
}
