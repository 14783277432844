<div class="content-carousel" [hidden]="active">
  <div class="content-text">
    <h4 class="h4">{{ title }}</h4>
    <span class="body1-default subtitle">{{ subtitle }}</span>
    <a [href]="link" target="_blank" class="fit-c">
      <button class="btn-anchor">
        <span class="button-text">{{ btnText }}</span>
        <img [src]="iconUrl" alt="" />
      </button>
    </a>
  </div>
  <div class="content-img">
    <img [src]="imgUrl" alt="" />
  </div>
</div>
