<div style="margin: 0 auto;">
    <div *ngIf="error===undefined" style="text-align: center;" >
        <div style="text-align: center;">
            <img style="margin-top: 40vh;width: 24px; margin-bottom: 12px;" src="/assets/images/tf_favicon.png" alt="">
            <mat-spinner style="margin: auto; " diameter="30" color="#ccc"></mat-spinner>
        </div>
    </div>
    <div *ngIf="error!==undefined" style="padding: 24px">
        {{error.toString()}}
    </div>
</div>

