<section class="sec-container">
  <div class="card-local">
    <div class="row">
      <app-card-link
        style="width: 100%"
        class="cards"
        [title]="'Documentos Oficiais'"
        [subtitle]="'Materiais Complementares'"
        [imgPath]="'/assets/images/homeTF/logo-tf.svg'"
        [link]="'https://pg.empreenderdinheiro.com.br/documentos-oficiais/'"
      ></app-card-link>
    </div>
  </div>
</section>
