import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  currentTopic: string = 'softwares';

  @Output() page = new EventEmitter<string>();

  updatePage() {
    this.page.emit(this.currentTopic);
  }

  constructor() {}

  ngOnInit(): void {}
}
