<header class="header">
  <a routerLink="/">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="191"
      height="40"
      viewBox="0 0 191 40"
      fill="none"
    >
      <path
        d="M22.9175 37.2694H21.4312C15.7596 37.2694 11.1461 32.6472 11.1461 26.9667V14.9843H22.9166V37.2704L22.9175 37.2694ZM14.1187 17.9611V26.9667C14.1187 30.4963 16.6234 33.4509 19.9459 34.1407V17.9611H14.1187Z"
        fill="url(#paint0_linear_584_584)"
      />
      <path
        d="M22.9285 14.9843H19.9348V21.7583H22.9285V14.9843Z"
        fill="url(#paint1_linear_584_584)"
      />
      <path
        d="M24.7021 17.9675H3.82153V16.4425C3.82153 10.9324 8.27943 6.44995 13.7595 6.44995H30.4805V12.1574C30.4805 15.3611 27.8883 17.9675 24.7021 17.9675ZM7.02337 14.9175H24.7021C26.216 14.9175 27.4472 13.6796 27.4472 12.1574V9.50088H13.7595C10.4738 9.50088 7.71586 11.8212 7.02337 14.9175Z"
        fill="url(#paint2_linear_584_584)"
      />
      <path
        d="M37.574 18.2241L35.9919 20.7852H33.0774V18.2241H37.574ZM39.9866 32.9713H37.015V20.8241C37.015 19.9575 37.3879 19.2658 38.1348 18.7482C38.6366 18.3991 39.1965 18.2241 39.8135 18.2241H44.194V20.7852H39.9866V32.9713Z"
        fill="#0B0C0E"
      />
      <path
        d="M48.9992 26.9556V30.4093H56.564L57.9536 32.9704H48.8251C47.8858 32.9704 47.1335 32.6149 46.5672 31.9028C46.2071 31.4371 46.0266 30.926 46.0266 30.3695V20.8223C46.0266 19.9556 46.3996 19.264 47.1455 18.7464C47.6473 18.3973 48.2072 18.2223 48.8242 18.2223H57.9527L56.5631 20.7834H48.9982V24.3927H55.9646V26.9538H48.9982L48.9992 26.9556Z"
        fill="#0B0C0E"
      />
      <path
        d="M62.4115 23.8315V27.363C62.4115 28.3722 62.7715 29.1806 63.4925 29.7889C63.9944 30.2028 64.5414 30.4102 65.1326 30.4102H67.1014C67.8989 30.4102 68.5748 30.1389 69.1273 29.5954C69.2047 29.5176 69.2691 29.4473 69.3207 29.3815L71.8871 31.3611C71.1661 32.0473 70.0537 32.5324 68.5481 32.8167C68.0204 32.9204 67.5379 32.9722 67.1005 32.9722H65.1317C63.5358 32.9722 62.1794 32.4028 61.0596 31.2648C59.9914 30.1778 59.4583 28.8778 59.4583 27.3648V23.8334C59.4583 22.2556 60.0246 20.9167 61.1563 19.8167C62.2761 18.7565 63.6003 18.226 65.1317 18.226H67.1005C68.3483 18.226 69.5573 18.5047 70.7287 19.0602C71.2177 19.3065 71.6035 19.5648 71.8862 19.8361L69.3198 21.8352C68.8695 21.2528 68.2451 20.9102 67.4477 20.8065C67.3187 20.7935 67.2027 20.7871 67.1005 20.7871H65.1317C64.244 20.7871 63.5303 21.1815 62.9898 21.9704C62.6039 22.5269 62.4105 23.1472 62.4105 23.8334L62.4115 23.8315Z"
        fill="#0B0C0E"
      />
      <path
        d="M77.1167 26.0055L78.1205 24.3953H83.4661V18.225H86.4377V32.9722H83.4661V26.9565H77.1167V32.9722H74.1644V18.225H77.1167V26.0065V26.0055Z"
        fill="#0B0C0E"
      />
      <path
        d="M92.2078 26.9556V32.9713H89.2555V20.8241C89.2555 19.9445 89.6284 19.2463 90.3753 18.7288C90.8771 18.3926 91.4296 18.2241 92.0347 18.2241H101.163L99.7736 20.7852H92.2087V24.3945H99.1943V26.9556H92.2087H92.2078Z"
        fill="#0B0C0E"
      />
      <path
        d="M102.784 18.2241H105.736V32.9713H102.784V18.2241Z"
        fill="#0B0C0E"
      />
      <path
        d="M111.545 18.1268C112.626 18.1268 113.501 18.7611 114.169 20.0287C114.273 20.2361 114.381 20.4685 114.497 20.7268L118.608 30.4676V18.2231H121.56V30.4676C121.56 31.2953 121.168 31.975 120.383 32.5046C119.83 32.8796 119.231 33.0676 118.589 33.0676C117.508 33.0676 116.633 32.4342 115.964 31.1657C115.861 30.9592 115.751 30.7259 115.636 30.4676L111.526 20.7268V32.9713H108.573V20.7268C108.573 19.8991 108.966 19.2203 109.75 18.6898C110.303 18.3148 110.901 18.1268 111.545 18.1268Z"
        fill="#0B0C0E"
      />
      <path
        d="M133.7 31.0111H127.273L128.855 28.45H132.811L130.303 21.2121L126.212 32.9713H123.047L127.35 20.6297C127.98 18.9093 128.964 18.0426 130.302 18.0297C131.64 18.0426 132.618 18.9093 133.235 20.6297L137.557 32.9713H134.392L133.698 31.0111H133.7Z"
        fill="#0B0C0E"
      />
      <path
        d="M142.172 18.1268C143.253 18.1268 144.128 18.7611 144.796 20.0287C144.9 20.2361 145.008 20.4685 145.124 20.7268L149.235 30.4676V18.2231H152.187V30.4676C152.187 31.2953 151.795 31.975 151.01 32.5046C150.457 32.8796 149.858 33.0676 149.216 33.0676C148.135 33.0676 147.26 32.4342 146.591 31.1657C146.488 30.9592 146.378 30.7259 146.263 30.4676L142.153 20.7268V32.9713H139.2V20.7268C139.2 19.8991 139.593 19.2203 140.377 18.6898C140.93 18.3148 141.528 18.1268 142.172 18.1268Z"
        fill="#0B0C0E"
      />
      <path
        d="M157.263 23.8315V27.363C157.263 28.3722 157.623 29.1806 158.344 29.7889C158.846 30.2028 159.392 30.4102 159.984 30.4102H161.953C162.75 30.4102 163.426 30.1389 163.979 29.5954C164.056 29.5176 164.121 29.4473 164.172 29.3815L166.739 31.3611C166.018 32.0473 164.905 32.5324 163.4 32.8167C162.872 32.9204 162.389 32.9722 161.952 32.9722H159.983C158.387 32.9722 157.031 32.4028 155.911 31.2648C154.843 30.1778 154.31 28.8778 154.31 27.3648V23.8334C154.31 22.2556 154.876 20.9167 156.008 19.8167C157.128 18.7565 158.452 18.226 159.983 18.226H161.952C163.2 18.226 164.409 18.5047 165.58 19.0602C166.069 19.3065 166.455 19.5648 166.738 19.8361L164.171 21.8352C163.721 21.2528 163.097 20.9102 162.299 20.8065C162.17 20.7935 162.054 20.7871 161.952 20.7871H159.983C159.096 20.7871 158.382 21.1815 157.841 21.9704C157.455 22.5269 157.262 23.1472 157.262 23.8334L157.263 23.8315Z"
        fill="#0B0C0E"
      />
      <path
        d="M171.698 26.9556V30.4093H179.263L180.653 32.9704H171.524C170.585 32.9704 169.833 32.6149 169.266 31.9028C168.906 31.4371 168.726 30.926 168.726 30.3695V20.8223C168.726 19.9556 169.099 19.264 169.846 18.7464C170.347 18.3973 170.907 18.2223 171.524 18.2223H180.653L179.263 20.7834H171.698V24.3927H178.665V26.9538H171.698V26.9556Z"
        fill="#0B0C0E"
      />
      <path
        d="M185.942 18.0823C185.942 19.174 185.09 20.0305 183.982 20.0305C182.873 20.0305 182.009 19.174 182.009 18.0823C182.009 16.9907 182.885 16.1573 183.982 16.1573C185.079 16.1573 185.942 17.0138 185.942 18.0823ZM182.499 18.0823C182.499 18.9388 183.129 19.6194 183.993 19.6194C184.857 19.6194 185.451 18.9388 185.451 18.0935C185.451 17.2481 184.833 16.5444 183.981 16.5444C183.129 16.5444 182.498 17.237 182.498 18.0814L182.499 18.0823ZM183.678 19.0916H183.234V17.1666C183.409 17.1314 183.654 17.1083 183.969 17.1083C184.331 17.1083 184.494 17.1666 184.634 17.249C184.739 17.3314 184.821 17.4833 184.821 17.6712C184.821 17.8823 184.658 18.0472 184.424 18.1175V18.1407C184.611 18.2111 184.716 18.3518 184.774 18.6101C184.832 18.9036 184.867 19.0212 184.914 19.0916H184.435C184.377 19.0212 184.342 18.8453 184.283 18.6222C184.248 18.4111 184.131 18.3166 183.886 18.3166H183.676V19.0907L183.678 19.0916ZM183.69 17.9999H183.9C184.145 17.9999 184.344 17.9175 184.344 17.7185C184.344 17.5425 184.216 17.4249 183.935 17.4249C183.818 17.4249 183.737 17.437 183.69 17.4481V17.9999Z"
        fill="#0B0C0E"
      />
      <defs>
        <linearGradient
          id="paint0_linear_584_584"
          x1="17.1299"
          y1="20.2055"
          x2="16.9334"
          y2="31.9953"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stop-color="#0D7DB4" />
          <stop offset="1" stop-color="#0A5DA1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_584_584"
          x1="20.0683"
          y1="22.6018"
          x2="23.2509"
          y2="12.8297"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stop-opacity="0" />
          <stop offset="0.29" stop-color="#040404" stop-opacity="0.17" />
          <stop offset="0.45" stop-color="#111111" stop-opacity="0.67" />
          <stop offset="0.52" stop-color="#1A1A1A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_584_584"
          x1="14.1112"
          y1="23.4055"
          x2="22.4072"
          y2="-2.06875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.15" stop-color="#0D7BB3" />
          <stop offset="0.21" stop-color="#0D7FB6" />
          <stop offset="0.28" stop-color="#0F8CBF" />
          <stop offset="0.35" stop-color="#11A2CF" />
          <stop offset="0.42" stop-color="#14C0E5" />
          <stop offset="0.49" stop-color="#19E4FF" />
        </linearGradient>
      </defs>
    </svg>
  </a>
  <nav class="header-nav navbar navbar-expand-md navbar-light">
 
    <a routerLink="/" mat-button class="body1-bold header-anchor">Início</a>
    <a
      mat-button
      class="body1-bold header-anchor"
      (click)="loginRoute('/user/members')"
    >
      Administração</a
    >
    <!-- <a
      mat-button
      class="body1-bold header-anchor"
      title="Em Breve"
      >Tutorial</a
    > -->
    <a
      [routerLink]="'/fale-conosco'"
      mat-button
      class="body1-bold header-anchor"
      >Suporte</a
    >
    <a (click)="logout()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
      
        <path
          d="M10.5 20.25C10.5 20.4489 10.421 20.6397 10.2803 20.7803C10.1397 20.921 9.94891 21 9.75 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H9.75C9.94891 3 10.1397 3.07902 10.2803 3.21967C10.421 3.36032 10.5 3.55109 10.5 3.75C10.5 3.94891 10.421 4.13968 10.2803 4.28033C10.1397 4.42098 9.94891 4.5 9.75 4.5H4.5V19.5H9.75C9.94891 19.5 10.1397 19.579 10.2803 19.7197C10.421 19.8603 10.5 20.0511 10.5 20.25ZM20.7806 11.4694L17.0306 7.71937C16.8899 7.57864 16.699 7.49958 16.5 7.49958C16.301 7.49958 16.1101 7.57864 15.9694 7.71937C15.8286 7.86011 15.7496 8.05098 15.7496 8.25C15.7496 8.44902 15.8286 8.63989 15.9694 8.78063L18.4397 11.25H9.75C9.55109 11.25 9.36032 11.329 9.21967 11.4697C9.07902 11.6103 9 11.8011 9 12C9 12.1989 9.07902 12.3897 9.21967 12.5303C9.36032 12.671 9.55109 12.75 9.75 12.75H18.4397L15.9694 15.2194C15.8286 15.3601 15.7496 15.551 15.7496 15.75C15.7496 15.949 15.8286 16.1399 15.9694 16.2806C16.1101 16.4214 16.301 16.5004 16.5 16.5004C16.699 16.5004 16.8899 16.4214 17.0306 16.2806L20.7806 12.5306C20.8504 12.461 20.9057 12.3783 20.9434 12.2872C20.9812 12.1962 21.0006 12.0986 21.0006 12C21.0006 11.9014 20.9812 11.8038 20.9434 11.7128C20.9057 11.6217 20.8504 11.539 20.7806 11.4694Z"
          fill="#191C1E"
        />
      </svg>

    </a>

  </nav>
</header>


