<div style="min-width: 100%; max-width: 480px;">
    <div *ngIf="clientId=== undefined">
        <h4 style="font-weight: 600; margin-bottom: 12px;">Busque por um cliente da sua lista</h4>
        <div>
            <mat-form-field style="margin-top: 12px; width: 100%;" class="example-form-field" appearance="fill">
                <mat-label>Busque pelo nome ou email</mat-label>
                <input #searchElement [formControl]="search" autocomplete="off" matInput type="text">
                <div *ngIf="loading" matSuffix>
                    <mat-spinner diameter="32"></mat-spinner>
                </div>
                <button *ngIf="search.value && loading == false" matSuffix mat-icon-button aria-label="Limpar"
                    (click)="search.setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="mat-list-content">

            <div *ngIf="usersList.length === 0" style="text-align: center; font-size: 12px;"> Nenhum resultado encontrado,
              utilize o campo de busca para encontrar clientes. </div>
            <mat-nav-list *ngIf="usersList.length > 0">
                <a *ngFor="let user of usersList" (click)="openLink(user._id)" mat-list-item
                    class="mat-list-item-primary">
                    <span class="application-item-title" mat-line>{{user.firstName}} {{user.lastName}}</span>
                    <span class="application-item-description" mat-line>{{user.email}}</span>
                </a>
            </mat-nav-list>
        </div>
    </div>
    <div *ngIf="clientId!== undefined">
        <app-select-client-study [application]="application" [clientId]="clientId">
        </app-select-client-study>
    </div>
</div>