import { HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultModule } from './layouts/default/default.module';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthGuard } from './shared/auth-guard.service';
import { DividaPipe } from './shared/divida.pipe';
import { MilionMoneyPipe } from './shared/milion-money.pipe';
import { NormalMoneyPipe } from './shared/normal-money.pipe';
import { FaleConoscoComponent } from './modules/fale-conosco/fale-conosco.component';
import { configureAuth } from './auth.config';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { CallbackComponent } from './callback/callback.component';
import { ModalOpenApplicationComponent } from './modal-open-application/modal-open-application.component';
import { MatListModule } from '@angular/material/list';
import { SelectClientComponent } from './modal-open-application/select-client/select-client.component';
import { SelectClientStudyComponent } from './modal-open-application/select-client-study/select-client-study.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HomepageComponent } from './modules/homepage/homepage.component';
import { HeaderComponent } from './modules/homepage/components/header/header.component';
import { MenuComponent } from './modules/homepage/components/menu/menu.component';
import { SoftwaresComponent } from './modules/homepage/components/softwares/softwares.component';
// import { CardComponent } from './modules/homepage/components/softwares/components/card/card.component';
import { CarouselComponent } from './modules/homepage/components/carousel/carousel.component';
import { ContentCarouselComponent } from './modules/homepage/components/content-carousel/content-carousel.component';
import { EducationComponent } from './modules/homepage/components/education/education.component';
import { EcosystemComponent } from './modules/homepage/components/ecosystem/ecosystem.component';
import { MaterialComponent } from './modules/homepage/components/material/material.component';
import { FooterComponent } from './modules/homepage/components/footer/footer.component';
import { CardNolinkComponent } from './modules/homepage/components/cards/card-nolink/card-nolink.component';
import { CardLinkComponent } from './modules/homepage/components/cards/card-link/card-link.component';
import { SoftwareModalComponent } from './modules/homepage/components/modals/software-modal/software-modal.component';
import { PrecisoComponent } from './preciso/preciso.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CadastroComponent,
    NormalMoneyPipe,
    MilionMoneyPipe,
    DividaPipe,
    RecoveryPasswordComponent,
    HomeComponent,
    FaleConoscoComponent,
    CallbackComponent,
    ModalOpenApplicationComponent,
    SelectClientComponent,
    SelectClientStudyComponent,
    HomepageComponent,
    HeaderComponent,
    MenuComponent,
    SoftwaresComponent,
    // CardComponent,
    CarouselComponent,
    ContentCarouselComponent,
    EducationComponent,
    EcosystemComponent,
    MaterialComponent,
    FooterComponent,
    CardNolinkComponent,
    CardLinkComponent,
    SoftwareModalComponent,
    PrecisoComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatDialogModule,
    MatListModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AuthModule.forRoot(),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    ,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
