import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';


@Injectable({
  providedIn: 'root'
})
export class UsersApplicationsService {

  constructor(
    public httpClient: HttpClient,
    public securityService: OidcSecurityService
  ) {

  }
  index(properties: any) {
 
    const fromObject: any = {};
    if (properties) {
      Object.keys(properties).map((property: string) => {
        fromObject[property] = `${properties[property]}`;
      });
    }
    return this.httpClient.get(environment.url + 'users/member', {
      params: new HttpParams({ fromObject }),
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      }
    });
  }
  getUser() {
    this.validateSessionOrRefresh();
    return this.httpClient.get(`${environment.url}users/member/profile`, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      }});
    
  }
  getSoftwareStudies(software: string, userId: string) {
    this.validateSessionOrRefresh();
    return this.httpClient.get(`${environment.url}users/member/${userId}/studies/` + software, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      }});
  }
  getAccessToken(id: string, application: string, studyId: string,) {
    this.validateSessionOrRefresh();
    return this.httpClient.post(`${environment.url}users/member/access/${id}/${application}/${studyId}`, {}, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      }});
  }

  duplicateAndGetAccessToken(id: string, application: string, studyId: string,) {
    this.validateSessionOrRefresh();
    return this.httpClient.post(`${environment.url}users/member/duplicate/${id}/${application}/${studyId}`, {}, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      }});
  }
  validateSessionOrRefresh() {
    this.securityService.isAuthenticated$.subscribe((isAuth) => {
      if(isAuth == false) {
        location.reload();
      }
    });
  }
}
