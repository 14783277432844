<section class="sec-container">
  <div class="card-local">
    <div class="row">
      <app-card-link
        class="cards col"
        [title]="'ED Seguros'"
        [subtitle]="'Proteções impossíveis de ignorar'"
        [imgPath]="'/assets/images/homeTF/logo-ed-seg.svg'"
        [tag]="'Disponível 18/12'"
        [link]="'https://pg.empreenderdinheiro.com.br/techfinance/ed-seguros/'"
      ></app-card-link>
      <app-card-link
        class="cards col"
        [title]="'ED Capital'"
        [subtitle]="'Asset as a Service'"
        [imgPath]="'/assets/images/homeTF/logo-ed-cap.svg'"
        [tag]="'Disponível 18/12'"
        [link]="'https://pg.empreenderdinheiro.com.br/techfinance/ed-capital/'"
      ></app-card-link>
    </div>
    <div class="row">
      <app-card-link
        class="cards col"
        [title]="'Hub de Crédito'"
        [subtitle]="'Taxas de juros menores, planejamentos melhores'"
        [imgPath]="'/assets/images/homeTF/logo-hub.svg'"
        [link]="
          'https://pg.empreenderdinheiro.com.br/techfinance/hub-de-credito/'
        "
      ></app-card-link>
      <app-card-link
        class="cards col"
        [title]="'Lvnt Inside Research'"
        [subtitle]="'Relatórios e Carteiras Recomendadas'"
        [imgPath]="'/assets/images/homeTF/logo-lvnt.svg'"
        [link]="'https://empreenderdinheiro.com.br/relatorios-inside/'"
      ></app-card-link>
      <app-card-link
        class="cards col"
        [title]="'Poupança Automática'"
        [subtitle]="'Guarde dinheiro sem perceber'"
        [imgPath]="'/assets/images/homeTF/logo-oinc.svg'"
        [link]="
          'https://useoinc.com.br/?utm_source=techfinance-site&utm_medium=web&utm_campaign=partnership&utm_id=techfinance'
        "
      ></app-card-link>
    </div>
  </div>
</section>
