<section class="modal flex-col">
  <header class="header-modal">
    <div class="d-flex g-1">
      <img [src]="'/assets/images/homeTF/logo-preciso.svg'" alt="" />
      <h3 class="h3">Preciso</h3>
    </div>
    <button class="close" mat-dialog-close>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.2806 18.2194C19.3503 18.2891 19.4056 18.3718 19.4433 18.4629C19.481 18.5539 19.5004 18.6515 19.5004 18.7501C19.5004 18.8486 19.481 18.9462 19.4433 19.0372C19.4056 19.1283 19.3503 19.211 19.2806 19.2807C19.2109 19.3504 19.1282 19.4056 19.0372 19.4433C18.9461 19.4811 18.8485 19.5005 18.75 19.5005C18.6515 19.5005 18.5539 19.4811 18.4628 19.4433C18.3718 19.4056 18.2891 19.3504 18.2194 19.2807L12 13.0604L5.78062 19.2807C5.63989 19.4214 5.44902 19.5005 5.25 19.5005C5.05097 19.5005 4.8601 19.4214 4.71937 19.2807C4.57864 19.1399 4.49958 18.9491 4.49958 18.7501C4.49958 18.551 4.57864 18.3602 4.71937 18.2194L10.9397 12.0001L4.71937 5.78068C4.57864 5.63995 4.49958 5.44907 4.49958 5.25005C4.49958 5.05103 4.57864 4.86016 4.71937 4.71943C4.8601 4.5787 5.05097 4.49963 5.25 4.49963C5.44902 4.49963 5.63989 4.5787 5.78062 4.71943L12 10.9397L18.2194 4.71943C18.3601 4.5787 18.551 4.49963 18.75 4.49963C18.949 4.49963 19.1399 4.5787 19.2806 4.71943C19.4214 4.86016 19.5004 5.05103 19.5004 5.25005C19.5004 5.44907 19.4214 5.63995 19.2806 5.78068L13.0603 12.0001L19.2806 18.2194Z"
          fill="#0A5DA1"
        />
      </svg>
    </button>
  </header>
  <div class="flex-col g-1">
    <span class="body1-default">Selecione o que deseja acessar:</span>
    <div class="d-flex modal-cards">
      <a
        routerLink="/preciso"
        id="preciso"
        mat-dialog-close
        style="text-decoration: none"
      >
        <app-card-link
          [imgPath]="'/assets/images/homeTF/web.svg'"
          [title]="'Gestão Ativa'"
          [isModal]="true"
          [subtitle]="'Versão Web'"
        ></app-card-link>
      </a>
      <app-card-link
        mat-dialog-close
        [imgPath]="'/assets/images/homeTF/plan.svg'"
        [title]="'Fluxo Futuro'"
        [subtitle]="'Versão Planilha'"
        [isModal]="true"
        [link]="'https://empreenderdinheiro.com.br/preciso-video/'"
      ></app-card-link>
    </div>
  </div>
</section>
