import {
  Component,
  OnDestroy,
  ContentChildren,
  AfterContentInit,
  QueryList
} from '@angular/core';
import { ContentCarouselComponent } from '../content-carousel/content-carousel.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(ContentCarouselComponent) childComponents!: QueryList<ContentCarouselComponent>;

  activeIndex: number = 0;
  intervalId: any;

  constructor() {}

  ngAfterContentInit() {
    this.activateComponent(this.activeIndex);
    this.intervalId = window.setInterval(() => {
      this.next();
    }, 12000);
  }

  activateComponent(index: number) {
    const components = this.childComponents.toArray();
    components.forEach((component, idx) => component.active = idx === index);
  }

  prev() {
    const length = this.childComponents.length;
    this.activeIndex = (this.activeIndex - 1 + length) % length;
    this.activateComponent(this.activeIndex);
  }

  next() {
    const length = this.childComponents.length;
    this.activeIndex = (this.activeIndex + 1) % length;
    this.activateComponent(this.activeIndex);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }
}
