<header class="bg-default-white d-flex shadow border-bottom">
  <app-header class="content-container bg-default-white"></app-header>
</header>
<div class="alert alert-primary" *ngIf="showDisplayNotification" role="alert">
  <div class="align-items-center">
    <div class="d-flex justify-content-between">
      <div></div>
      <div class="">
        <div
          class="btn btn-sm border border-secondary p-2 font-weight-bold mr-2"
          style="border-color: #5e9ef4 !important"
        >
          <div class="mx-3 d-flex justify-content-center align-content-center">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 8C15.5 11.866 12.366 15 8.5 15C4.63401 15 1.5 11.866 1.5 8C1.5 4.13401 4.63401 1 8.5 1C12.366 1 15.5 4.13401 15.5 8ZM3.1229 6.26386C3.8974 3.86461 6.12383 2.33949 8.51756 2.34861C8.99269 2.35021 9.47442 2.41226 9.95271 2.53991V6.78117C9.95271 7.14802 9.65256 7.44313 9.28338 7.43344C8.92662 7.42335 8.64857 7.1232 8.64857 6.76876V6.48998L8.64852 6.48994V3.70716C6.77784 3.64048 5.01575 4.81199 4.41274 6.68267C4.03116 7.87125 4.19364 9.10442 4.76214 10.1135L4.76205 10.1138C5.10209 10.7152 5.58698 11.2362 6.18721 11.6196V13.153C3.59057 11.9911 2.23175 9.0354 3.1229 6.26386ZM10.9621 4.47964C11.5145 4.86505 11.9603 5.37132 12.2741 5.94864L12.2736 5.9486C12.815 6.94522 12.9627 8.15125 12.5862 9.31811C12.0005 11.1359 10.3138 12.2952 8.50078 12.2963V9.67293L8.49809 9.67479V9.30609C8.49809 8.95165 8.22004 8.6515 7.86327 8.64141C7.4941 8.63172 7.19395 8.92683 7.19395 9.29368V13.4973C9.82951 14.1253 12.5415 12.7824 13.6462 10.326C13.7327 10.134 13.8095 9.93517 13.8756 9.72993C13.9559 9.48128 14.018 9.23107 14.0626 8.98076C14.3207 7.53826 13.9971 6.09441 13.2363 4.92107C13.2248 4.90339 13.2133 4.88577 13.2016 4.86822L13.2016 4.86829C12.6557 4.04755 11.8928 3.36431 10.9621 2.91528V2.91413L10.9613 2.91491L10.9621 2.91528V4.47964Z" fill="#001847"/>
              </svg>
              
            <span>Ed Seguros</span>
          </div>
        </div>
        <span class="p-2">
          Solicite agora o estudo de Plano de Saúde e Odonto (PJ de 2 a 99 vidas) - Confira →
        </span>
        <a
          class="btn font-weight-bold"
          href="https://empreenderdinheiro.com.br/guia-proposta-de-trabalho/"
          target="_blank"
          ><span>Conferir Atualização </span>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3538 8.35378L9.85375 12.8538C9.75993 12.9476 9.63268 13.0003 9.5 13.0003C9.36732 13.0003 9.24007 12.9476 9.14625 12.8538C9.05243 12.76 8.99972 12.6327 8.99972 12.5C8.99972 12.3674 9.05243 12.2401 9.14625 12.1463L12.7931 8.50003H3C2.86739 8.50003 2.74021 8.44736 2.64645 8.35359C2.55268 8.25982 2.5 8.13264 2.5 8.00003C2.5 7.86743 2.55268 7.74025 2.64645 7.64648C2.74021 7.55271 2.86739 7.50003 3 7.50003H12.7931L9.14625 3.85378C9.05243 3.75996 8.99972 3.63272 8.99972 3.50003C8.99972 3.36735 9.05243 3.2401 9.14625 3.14628C9.24007 3.05246 9.36732 2.99976 9.5 2.99976C9.63268 2.99976 9.75993 3.05246 9.85375 3.14628L14.3538 7.64628C14.4002 7.69272 14.4371 7.74786 14.4623 7.80856C14.4874 7.86926 14.5004 7.93433 14.5004 8.00003C14.5004 8.06574 14.4874 8.13081 14.4623 8.1915C14.4371 8.2522 14.4002 8.30735 14.3538 8.35378Z"
              fill="#001E2E"
            />
          </svg>
        </a>
      </div>
      <div>
        <button
          type="button"
          (click)="closeDisplayNotification()"
          class="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
<section class="flex-col bg-figma-white">
  <div class="welcome h2 content-container bg-figma-white p-5">
    Bem-vindo à TechFinance®, {{ currentUser.user.firstName }}!
  </div>
  <app-menu
    class="content-container bg-figma-white"
    (page)="update($event)"
  ></app-menu>
  <app-softwares
    *ngIf="currentPage == 'softwares'"
    class="content-container"
  ></app-softwares>
  <app-education
    *ngIf="currentPage == 'education'"
    class="content-container"
  ></app-education>
  <app-ecosystem
    *ngIf="currentPage == 'ecosystem'"
    class="content-container"
  ></app-ecosystem>
  <app-material
    *ngIf="currentPage == 'material'"
    class="content-container"
  ></app-material>

  <!-- <app-carousel class="content-container"> -->
  <!-- <app-content-carousel
      [title]="'Consultoria Financeira Empresarial'"
      [subtitle]="
        'Ajude pequenas e médias empresas e seja muito bem-remunerado(a) por isso. Conheça nossa solução Corporate e turbine seu volume de receitas.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-tfb.png'"
      [btnText]="'agendar demonstração'"
      [iconUrl]="'/assets/images/homeTF/icon-right.svg'"
      [link]="
        'https://pg.empreenderdinheiro.com.br/pc/demonstracao-techfinance-business/'
      "
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'18 DE MARÇO, ÀS 19H:30 AO VIVO NO YOUTUBE | EXCLUSIVO'"
      [subtitle]="
        'O novo (e não convencional) caminho para faturar 30 mil mensais recorrentes com Wealth Planning. Exclusivo para associados TechFinance®.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-o-novo-metodo-tf.png'"
      [btnText]="'Quero meu convite para conhecer o novo método!'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="
        'https://pg.empreenderdinheiro.com.br/lc/masterclass-o-novo-metodo/?utm_source=area-de-membros-tf&utm_medium=banner-tf&utm_campaign=ec_geracao-leads_MASTERCLASS-NOVO-METODO-EC'
      "
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'Torne sua empresa um escritório credenciado à ED'"
      [subtitle]="
        'Se seu objetivo é liderar um time de Wealth Planners, ter seu próprio escritório e escalabilidade, conheça o modelo de escritórios credenciados.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-escritorio.png'"
      [btnText]="'Quero Conhecer o modelo'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="'https://empreenderdinheiro.com.br/escritorio-ed-wpp'"
    ></app-content-carousel> -->

  <!-- <app-content-carousel
      [title]="'Está sem tempo para aprender?'"
      [subtitle]="
        'Acesse gratuitamente o Clube do Livro e descubra o caminho para dominar o conhecimento das maiores mentes do mercado.'
      "
      [imgUrl]="'/assets/images/homeTF/banner-club-do-livro.png'"
      [btnText]="'acessar clube do livro'"
      [iconUrl]="'/assets/images/homeTF/icon-open.svg'"
      [link]="'https://open.spotify.com/user/fk8flssez347genij7r2vz8ms'"
    ></app-content-carousel> -->
  <!-- </app-carousel> -->
</section>
<div class="col-md-12">
<div class="d-flex justify-content-center bg-figma-white">
  <!-- <a
    [href]="'https://pg.empreenderdinheiro.com.br/lv/money-heroes-2024-tf/'"
    target="_blank"
    class="text-decoration-none link-blur"
  >
    <div>
      <img
        [src]="'/assets/images/homeTF/BANNER- MONEY HEROES (TF) - 08 2024.png'"
        alt="Banner TechFinance Business"
        class="img-fluid rounded"
        style="width: 1360px"
      />
    </div>
  </a> -->
  <a
    [href]="'https://pg.empreenderdinheiro.com.br/lc/lista-de-espera-money-heroes-day/'"
    target="_blank"
    class="text-decoration-none link-blur"
  >
    <div>
      <img
        [src]="'/assets/images/homeTF/BANNER- MONEY HEROES (TF) - 08 2024.png'"
        alt="Banner TechFinance Business"
        class="img-fluid rounded"
        width="1740px"
      />
    </div>
  </a>

  
</div>
</div>


<div class="p-4 bg-figma-white"></div>
<div class="" #footer>
  <footer class="d-flex bg-default-white border-top mt-auto">
    <app-footer class="content-container"></app-footer>
  </footer>
</div>


