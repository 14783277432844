<div class="container_full">
  <div class="container">
    <section *ngxPermissionsOnly="['loaded']">
      <div class="">
        <h3 style="border-left: 4px solid #0da5ba">Ferramentas:</h3>
        <div class="row">
          <div class="col-6 col-md-3">
            <a class="item" (click)="openApplication('apolo')">
              <div class="image">
                <img src="/assets/images/banner_apolo.jpg" alt="" />
              </div>
              <span style="text-align: center">Sistema Apolo®</span>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a class="item" (click)="openApplication('oraculo')">
              <div class="image">
                <img src="/assets/images/banner_oraculo.jpg" alt="" />
              </div>
              <span>Sistema Oráculo®</span>
            </a>
          </div>
          <div class="col-6 col-md-3">
            <a class="item" (click)="openApplication('selectus')">
              <div class="image">
                <img src="/assets/images/banner_selectus.jpg" alt="" />
              </div>
              <span>Sistema Selectus®</span>
            </a>
          </div>

          <div class="col-6 col-md-3">
            <a
              class="item"
              [href]="['https://empreenderdinheiro.com.br/preciso-video/']"
            >
              <div class="image">
                <img src="/assets/images/banner_preciso.jpg" alt="" />
              </div>
              <span>Sistema Preciso®</span>
            </a>
          </div>
        </div>
      </div>
      <div style="height: 42px"></div>
      <div class="row">
        <div class="col-12 col-md-9">
          <h3 style="border-left: 4px solid #0bbe87">Material Técnico:</h3>
          <div class="row">
            <div class="col-6 col-md-4">
              <a
                class="item"
                [href]="[
                  'https://empreenderdinheiro.com.br/relatorios-inside/'
                ]"
                target="_blank"
              >
                <div class="image">
                  <img src="/assets/images/banner_inside.png" alt="" />
                </div>
                <span>Relatórios Inside</span>
              </a>
            </div>
            <div class="col-6 col-md-4">
              <a
                class="item"
                [href]="[
                  'https://empreenderdinheiro.com.br/masterplan-techfinance/'
                ]"
                target="_blank"
              >
                <div class="image">
                  <img src="/assets/images/banner_mp.jpg" alt="" />
                </div>
                <span>Inicie pelo Masterplan</span>
              </a>
            </div>
            <div class="col-6 col-md-4">
              <a
                class="item"
                [href]="[
                  'https://pg.empreenderdinheiro.com.br/documentos-oficiais/'
                ]"
                target="_blank"
              >
                <div class="image">
                  <img src="/assets/images/banner_documentos.jpg" alt="" />
                </div>
                <span>Documentos Oficiais</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-3">
          <h3 style="border-left: 4px solid #0e40bb; font-size: 24px">
            Consultoria PJ:
          </h3>
          <div class="row">
            <div class="col-12 col-md-12">
              <a
                class="item"
                [href]="[
                  'https://business.tf.empreenderdinheiro.com.br/login/'
                ]"
                target="_blank"
              >
                <div class="image">
                  <img src="/assets/images/banner_cfed.png" alt="" />
                </div>
                <span>Consultoria Empresarial</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 42px"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 style="border-left: 4px solid #15b84f">Best-sellers:</h3>
          <div class="row">
            <div class="col-6 col-md-6">
              <a
                class="item"
                [href]="['https://empreenderdinheiro.com.br/cursos/youcash/']"
              >
                <div class="image">
                  <img src="/assets/images/banner_youcash.jpg" alt="" />
                </div>
                <span>Programa YouCash</span>
              </a>
            </div>
            <div class="col-6 col-md-6">
              <a
                class="item"
                [href]="[
                  'https://empreenderdinheiro.com.br/cursos/oratoria-persuasiva/'
                ]"
              >
                <div class="image">
                  <img src="/assets/images/banner_oratoria.jpg" alt="" />
                </div>
                <span>Programa Oratória Persuasiva</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <h3 style="border-left: 4px solid #0c5991">Escale seu negócio:</h3>
          <div class="row">
            <div class="col-6 col-md-6">
              <a
                class="item"
                [href]="[
                  'https://empreenderdinheiro.com.br/solucoes/mentoria-equity/'
                ]"
                target="_blank"
              >
                <div class="image">
                  <img src="/assets/images/banner_equity.jpg" alt="" />
                </div>
                <span>Mentoria Equity</span>
              </a>
            </div>
            <div class="col-6 col-md-6">
              <a
                class="item"
                [href]="['https://empreenderdinheiro.com.br/franquia/']"
              >
                <div class="image">
                  <img src="/assets/images/banner_franquia.png" alt="" />
                </div>
                <span>Franquia Empreender Dinheiro</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 42px"></div>
      <div class="row">
        <div class="col-md-12">
          <a
            class="item"
            [href]="['https://empreenderdinheiro.com.br/tf-banner-home/']"
            target="_blank"
          >
            <div class="image">
              <img
                src="/assets/images/banner-movimento.png?cacheVersion=2"
                style="border-radius: 0px"
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
