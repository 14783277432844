import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PrecisoService {
  constructor(
    public httpClient: HttpClient,
    public oidcSecurityService: OidcSecurityService
  ) {}

  initSessionPlanner(userData: any) {
    return this.httpClient.post(
      environment.url + 'preciso/planner/initSession',
      userData,
      {
        withCredentials: false,
      }
    );
  }
}
