import { Component, Input, OnInit } from '@angular/core';
import { UsersApplicationsService } from '../users-applications.service';
import { environment } from './../../../environments/environment';
import { v4 } from 'uuid';

@Component({
  selector: 'app-select-client-study',
  templateUrl: './select-client-study.component.html',
  styleUrls: ['./select-client-study.component.scss']
})
export class SelectClientStudyComponent implements OnInit {
  studiesList: any[] = [];
  @Input()
  application: string;
  @Input()
  clientId: string;
  @Input()
  isPersonStudies: boolean = false;
  loading = true;

  constructor(
    public usersApplicationsService: UsersApplicationsService,
  ) { }

  ngOnInit(): void {

    this.usersApplicationsService.getSoftwareStudies(this.application, this.clientId)
      .subscribe((studiesList: any[]) => {
        this.loading = false;
        this.studiesList = studiesList;
      }); 
  }
  create() {
    this.openLink(v4());
  }
  openLink(id: string) {
    this.usersApplicationsService.getAccessToken(this.clientId, this.application, id)
    .subscribe((response: any) => {
      this.openFolder(this.application, response.accessToken);
    },() => {
      alert('Tivemos um problema ao abrir a pasta deste usuário.')
    })
  }
  duplicateLink(event: any, id: string, application: string) {
    event.stopPropagation();
    event.preventDefault();
    if(this.clientId === undefined) {
      return;
    }
    this.usersApplicationsService.duplicateAndGetAccessToken(this.clientId, application, id)
    .subscribe((response: any) => {
      this.openFolder(application, response.accessToken);
    },() => {
      alert('Tivemos um problema ao abrir a pasta deste usuário.')
    })
  }
  openFolder(software: string, token: string) {
    location.href = environment.clientRedirect.replace('techfinance', software).replace('.qa.', '.qa.') + '/folder-token/callback/' + token;
  }
}
