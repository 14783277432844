import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { UsersApplicationsService } from './users-applications.service';

@Component({
  selector: 'app-modal-open-application',
  templateUrl: './modal-open-application.component.html',
  styleUrls: ['./modal-open-application.component.scss']
})
export class ModalOpenApplicationComponent implements OnInit {

  simpleRouteEnum: 'home' | 'select-client' | 'my-studies' = 'home';
  application: string;
  myId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {application: string},
    public usersApplications: UsersApplicationsService,
  ) {
    this.application = data.application;
  }

  ngOnInit(): void {
    this.usersApplications.getUser().subscribe((data: any)=> this.myId = data._id)
  }
  openLink(subdomain: string, route: string) {
    if(subdomain==='login' && environment.isQa === true) {
      location.href = ('https://' + subdomain + '.' + environment.baseUrl + route).replace('login.tf', 'login');
      return;
    } 
    location.href = (environment.clientRedirect.replace('techfinance', subdomain) + route).replace('.qa.', '.qatf.').replace('login.tf', 'login');
  }
}
