<a *ngIf="!isModal" [ngClass]="block ? 'block' : null" class="card">
  <img class="card-icon" [src]="imgPath" />
  <span class="h5">{{ title }}</span>
  <span class="body1-default">{{ subtitle }}</span>
  <span *ngIf="tag" class="tag body1-bold">{{ tag }}</span>
</a>

<a
  *ngIf="isModal"
  [ngClass]="block ? 'block' : null"
  class="d-flex card-modal g-1"
>
  <img class="g-1" [src]="imgPath" alt="" />
  <div class="flex-col g-1">
    <span class="h5 modal-title">{{ title }}</span>
    <span class="body1-default">{{ subtitle }}</span>
    <span class="tag body1-bold">{{ tag }}</span>
  </div>
</a>
