<div style="width: 100%; max-width: 480px;">

  <h4 style="font-weight: 600; margin-bottom: 12px;">Selecione um estudo</h4>
  <div class="mat-list-content">
    <div *ngIf="loading == true" style="text-align: center; font-size: 12px;">
      <div style="padding: 6px;">Carregando...</div>
    </div>

    <div *ngIf="loading == false">
    <div *ngIf="studiesList.length === 0 && isPersonStudies == true" style="text-align: center; font-size: 12px;">
      <div>Você ainda não possui estudos.</div>
    </div>
    <div *ngIf="studiesList.length === 0 && isPersonStudies == false" style="text-align: center; font-size: 12px;">
      <div>O cliente selecionado ainda não possui estudos nesse software.</div>
    </div>
    <mat-nav-list class="studies-list" *ngIf="studiesList.length > 0">
      <a *ngFor="let study of studiesList; let i = index" (click)="openLink(study.uuid)" mat-list-item
        class="mat-list-item-primary" style="max-width: 100%;">
        <span class="application-item-title" style="font-weight: bold;" mat-line>{{study.name}}</span>
        <span class="application-item-description" mat-line>Ultima atualização {{study.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span>
        <span class="mobile-actions" style="min-width: 177px;padding-left: 11px;">
          <button color="primary" style="margin-right: 12px; background-color: #0153cc; color: #fff;"
            matTooltip="Clique aqui para abrir este estudo" mat-flat-button color="primary">
            Abrir Estudo
          </button>
          <button matTooltip="Clique aqui para duplicar este estudo" style="color: #0153cc;" mat-stroked-button
            mat-icon-button color="primary" (click)="duplicateLink($event, study.uuid, study.software)">
            <mat-icon> content_copy</mat-icon>
          </button>
        </span>
      </a>
    </mat-nav-list>
  </div>
    <mat-nav-list>
      <button (click)="create()" style="font-weight: bold;" mat-stroked-button><b>Criar novo estudo</b></button>
    </mat-nav-list>
  </div>
</div>
