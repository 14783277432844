<mat-toolbar color="primary">
  <div class="container">
    <mat-toolbar-row style="padding: 0px;">
      <a>
        <span class="d-none d-sm-block" style="margin-left: 0px; margin-right: 16px; pointer-events: none">
          <img src="/assets/images/logo_tf_horizontal.png" style="max-width: 196px;" alt="">
        </span>
        <span class="d-block d-sm-none" style="margin-left: 0px; margin-right: 8px; pointer-events: none">
          <img src="/assets/images/tf_favicon.png" style="max-width: 196px;" alt="">
        </span>
      </a>
      <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
        <div class="d-none d-sm-block">
          <ul class="list-menu" fxLayout="row" fxLayoutGap="8px">
            <li>
              <a target="_blank" (click)="openModal()">
                <mat-icon>navigation_outline</mat-icon>
                Comece por Aqui
              </a>
            </li>
            <li>
              <a [routerLink]="['fale-conosco']">
                <mat-icon>chat_bubble_outline</mat-icon>
                Fale Conosco
              </a>
            </li>
            <li>
              <a style="position: relative;" *ngxPermissionsOnly="['members::list::my']"
                (click)="loginRoute('/user/members')">
                <mat-icon>people</mat-icon>
                Administração<sub style="font-size: 5px; position: absolute; right: 0;">beta</sub>
              </a>
            </li>
          </ul>
        </div>
        <div class="d-block d-sm-none" style="margin-top: 28px;" fxLayout="row" fxLayoutGap="12px">
          <div>
            <a target="_blank" (click)="openModal()">
              <mat-icon style="margin-left: 14px; margin-right: 14px;">navigation_outline</mat-icon>
            </a>
            <a [routerLink]="['fale-conosco']">
              <mat-icon style="margin-left: 14px; margin-right: 14px;">chat_bubble_outline</mat-icon>
            </a>
            <a *ngxPermissionsOnly="['members::list::my']"
              (click)="loginRoute('/user/members')">
              <mat-icon style=" margin-left: 14px;">people</mat-icon>
            </a>
          </div>
        </div>
        <ul style="padding-inline-start: 0px;" fxLayout="row" fxLayoutGap="0px">
          <li style="margin-top: 12px!important;">
            <button mat-icon-button style="margin-top: 18px; text-align: center; padding-left: 4px;"
              [matMenuTriggerFor]="menu">
              <mat-icon>exit_to_app</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button (click)="logout()" mat-menu-item>
                <mat-icon style="margin-top: 10px;">exit_to_app</mat-icon>
                Sair
              </button>
            </mat-menu>
          </li>
        </ul>
      </div>
    </mat-toolbar-row>
  </div>
</mat-toolbar>