import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { PrecisoService } from './preciso.service';

@Component({
  selector: 'app-preciso',
  templateUrl: './preciso.component.html',
  styleUrls: ['./preciso.component.scss'],
})
export class PrecisoComponent implements OnInit {
  constructor(
    public httpClient: HttpClient,
    public oidcSecurityService: OidcSecurityService,
    private precisoService: PrecisoService
  ) {}

  urlPreciso: string = 'https://plataformaorcamento.com.br/access/';
  token: string = '';
  dataRequest: any;

  async ngOnInit() {
    await this.getDataProfile();
    await this.initSessionPlanner();
    await this.setIframeSrc();
  }

  async initSessionPlanner() {
    try {
      const data: any = await this.precisoService
        .initSessionPlanner(this.dataRequest)
        .toPromise();

      this.urlPreciso += data.key;
    } catch (error) {
      this.urlPreciso = 'https://plataformaorcamento.com.br/preciso/login';
      console.error('Erro ao iniciar a sessão do planner:', error);
    }
  }

  setIframeSrc() {
    const iframe = document.getElementById(
      'iframe'
    ) as HTMLIFrameElement | null;

    if (iframe) {
      iframe.src = this.urlPreciso;
    }
  }

  getDataProfile() {
    this.oidcSecurityService.userData$.subscribe((profileData) => {
      if (profileData.user.first_name == '') {
        profileData.user.first_name = 'Usuário';
      }

      if (profileData.user.lastName == '') {
        profileData.user.lastName = 'TF';
      }

      this.dataRequest = {
        first_name: profileData.user.firstName,
        last_name: profileData.user.lastName,
        email: profileData.user.email,
        external_id: profileData.user._id,
      };
    });
  }
}
